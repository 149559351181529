<script lang="ts" setup>
  import VButton from '~/components/ui/VButton.vue';
  import type { RouteLocationRaw } from 'vue-router';
  import type { TEmptyBlock } from '~/types/common';

  interface IProps {
    template?: TEmptyBlock;
    link?: {
      name: string;
      route: RouteLocationRaw;
    };
  }

  const props = withDefaults(defineProps<IProps>(), {
    template: 'period',
    link: () => ({ name: 'Перейти в каталог', route: { name: 'browse' } }),
  });

  const emit = defineEmits<{
    close: [];
  }>();
  
  const router = useRouter();
  const route = useRoute();
  enum EnumTemplate {
    'period' = 'К сожалению, новинок за выбранный период не найдено',
    'favorite' = 'Здесь будут показаны ваши любимые фильмы и сериалы',
    'purchases' = 'Покупайте и арендуйте фильмы и они появятся здесь',
    'continue' = 'Здесь будут показаны  фильмы и сериалы, которые вы смотрите',
    'viewed' = 'Здесь будут показаны  фильмы и сериалы, которые вы посмотрели',
    'filter' = 'К сожалению фильтрация по выбранным категориям не дала результата',
    'search' = 'Поиск не дал результата',
    'channels' = 'К сожалению нет доступных трансляций',
    'cheels' = 'Поиск не дал результатов'
  }

  const templateText = computed(() => EnumTemplate[props.template]);
  const handleClick = () => {
    const { name } = props.link?.route;
    if (route.name !== name) {
      router.push({ name});
    }
    emit('close');
  }
</script>

<template>
  <div class="empty-block">
    <slot>
      <h2 class="empty-block__header">{{ templateText }}</h2>
      <slot name="button">
        <v-button class="empty-block__button" media="normal" @click="handleClick">{{ props.link.name }}</v-button>
      </slot>
    </slot>
  </div>
</template>

<style lang="scss" scoped>
  .empty-block {
    padding: 56px 0;
    display: grid;
    gap: 24px;
    justify-content: center;

    &__header {
      color: $text-color-main;
      font-size: 18px;
      font-weight: 400;
      line-height: 28px;
      text-align: center;
    }

    &__button {
      width: fit-content;
      justify-self: center;
    }
  }
</style>
